<template>
    <div>
        <headTitle :isMain="2"/>
        <div class="news-box">
                <div class="title">{{ newsDetail.title }}</div>
                <div class="date">{{newsDetail.date}}</div>
                <div class="line"></div>
                <div class="content" v-html="newsDetail.content">
                </div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import { getAction } from '@/utils/request.js';
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            newsDetail:{
                title:'',
                date:'',
                content:'',
            }
        };
    },

    mounted() {
        this.init()
    },

    methods: {
        async init(){
            const res = await getAction(`/wp/wp-json/wp/v2/posts/${this.$route.params.id}`);
            if(res){
                this.newsDetail.title = res.title.rendered
                let date = res.date.split('T')
                let newDate = date[0].split('-')[0] + '/' + date[0].split('-')[1] + '/' + date[0].split('-')[2]
                this.newsDetail.date = newDate
                this.newsDetail.content = res.content.rendered
                this.newsDetail.content = this.newsDetail.content.replaceAll("http://69.230.196.6:8088", "https://www.bizfocus.cn/wp")
            }
        },
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.news-head-line{
    width: 190rem;
    border: 1px solid #F3FAF6;
    margin-top: 11rem;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.2);
}
.news-img{
    width: 100%;
    height: 30rem;
    margin-top: 4rem;
}
.news-img2{
    width: 65.4rem;
    height: auto;
    margin-top: 4rem;
}
.news-box{
    padding-top: 8rem;
    width: 66.5rem;
    margin: auto;
    margin-top: 3rem; 
    .title{
        // height: 2.6rem;
        font-size: 2.2rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #333333;
        line-height: 3.9rem;
        text-align: justify;
        margin-top: 2rem;
    }
    .date{
        height: 1.9rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #999999;
        line-height: 2.4rem;
        text-align: justify;
        margin-top: 2rem;
    }
    .line{
        width: 100%;
        height: 1px;
        border: 1px solid #EDEDED;
        margin-top: 1rem;
    }
    .content{
        width: 100%;
        // height: 100rem;
        font-size: 2.2rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #4F4F4F;
        line-height: 4rem;
        margin-top: 2.4rem;
        text-align: justify;
    }
}
</style>
